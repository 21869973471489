<template>
  <div class="p-4 search-result-card">
    <router-link to="/" class="">
      <div class="text-dark-gray flex align-center">
        <v-icon color="text-dark-gray"
          >mdi-book-open-page-variant-outline
        </v-icon>
        <span class="text-xl ml-2">{{ type }}</span>
      </div>
      <div class="text-gray-400 text-sm pt-2">
        {{ type }}
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "collection"
    }
  }
};
</script>

<style lang="scss">
.search-result-card {
  border-bottom: 1px dashed var(--gray);

  a {
    color: var(--dark-gray);

    i {
      &.v-icon {
        color: var(--dark-gray);
      }
    }
  }
}
</style>
