<template>
  <div class="container my-4">
    <v-tabs v-model="selectedTab">
      <v-tab href="#all">All</v-tab>
      <v-tab href="#collection">Collection</v-tab>
      <v-tab href="#page">Page</v-tab>
      <v-tab href="#ticket" disabled>Ticket</v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item value="all">
        <div>
          <search-result-card type="collection" />
          <search-result-card type="collection" />
          <search-result-card type="collection" />
          <search-result-card type="collection" />
          <search-result-card type="collection" />
          <search-result-card type="collection" />
          <search-result-card type="collection" />
          <search-result-card type="collection" />
        </div>
      </v-tab-item>
      <v-tab-item value="collection">
        <div>
          <search-result-card type="collection" />
          <search-result-card type="collection" />
          <search-result-card type="collection" />
          <search-result-card type="collection" />
        </div>
      </v-tab-item>
      <v-tab-item value="page">
        <div>
          <search-result-card type="collection" />
          <search-result-card type="collection" />
          <search-result-card type="collection" />
        </div>
      </v-tab-item>
      <v-tab-item value="ticket">
        <div>
          <search-result-card type="collection" />
          <search-result-card type="collection" />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import SearchResultCard from "@/components/SearchResultCard";

export default {
  name: "Search",
  components: { SearchResultCard },
  data() {
    return {
      selectedTab: "#All"
    };
  }
};
</script>

<style scoped></style>
